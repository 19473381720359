<template>
    <div>
        <!-- <van-nav-bar title="门店地址" /> -->
        <div id="address" class="address" v-if="list.length">
            <div v-for="(item,index) in list" :key="index">
                <h3 @click="clickAddress(item.latitude,item.longitude,item.store_name,item.address)">{{item.store_name}}
                    <div style="line-height: 1.5;color:#0abbb9;" v-if="item.distance">{{getNumber(item.distance)}}</div>
                </h3>
                <div class="map" :id="'container'+index"></div>
                <div @click="clickAddress(item.latitude,item.longitude,item.store_name,item.address)" class="map_mask"></div>
                <a :href="`tel:${item.contact_tel}`" style="color:#333">
                    <div class="displayFlex justifyContentBetten" v-if="item.contact_name">
                        <div class="left">{{item.contact_name}}</div>
                        <div class="displayFlex" style="align-items: center;">
                            <van-icon name="phone-o" /> {{item.contact_tel}}</div>
                    </div>
                </a>
                <div @click="clickAddress(item.latitude,item.longitude,item.store_name,item.address)" class="displayFlex justifyContentBetten" v-if="item.address">
                    <div class="left">
                        {{item.address}}
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="data_ishow && !list.length" class="null-data block">
            <div>
                <img :src="src + 'null_index.png'" />
                <div>暂无数据</div>
            </div>
        </div>
    </div>
</template>
<script>
import "@/assets/css/index.css";
import config from "@/utils/config.js";
// import TMap from "@/assets/js/AMap.js";
// import iconImage from "@/assets/img/addressList.png";
// import { Toast } from "vant";
// import { getList } from "@/api/address";
// import { getJSsdk } from "@/api/public";
import wx from "weixin-js-sdk";
export default {
    name: "test",
    data() {
        return {
            list: [],
            data_ishow: false,
            location_value: 1,
            lj: null,
            show: false,
            map: null,
            latitude: "",
            longitude: "",
            src: `${config.ossImageUrl}`,
        };
    },
    mounted() {
        this.$store.commit("hideTabbar");
        if (this.$route.query.team_id) {
            localStorage.setItem("team_id", this.$route.query.team_id);
        }
        this.getWxSDK();
    },
    methods: {
        // 开始
        // preventDefault
        touchstart(e) {
            console.log('开始', e)
            this.show = true
        },
        // 结束
        aaa() {
            this.show = false
        },
        touchmove(e) {
            console.log('开始', e)
        },
        getNumber(num) {
            if (num > 500) {
                return ">500KM";
            } else {
                return num.toFixed(2) + "KM";
            }
        },
        getList() {
            var data = {
                team_id: localStorage.getItem("team_id"),
                latitude: this.latitude,
                longitude: this.longitude,
            };
            this.Request('client/store/list', 'get', data, false).then((res) => {
                if (res.status == 0) {
                    var list = res.data;
                    if (list.length) {
                        list.filter((v) => {
                            if (v.longitude && v.latitude) {
                                this.list.push(v);
                            }
                        });

                        let that = this;

                        this.$nextTick(() => {
                            that.list.filter((v, i) => {
                                var center = new window.TMap.LatLng(v.latitude, v.longitude)
                                that.map = new window.TMap.Map("container" + i, {
                                    center,
                                    zoom: 17.2,   //设置地图缩放级别
                                    pitch: 0, // 俯仰度
                                    rotation: 0, // 旋转角度
                                });
                                new window.TMap.MultiMarker({
                                    map: that.map,
                                    styles: {
                                        // 点标记样式
                                        marker: new window.TMap.MarkerStyle({
                                            width: 20, // 样式宽
                                            height: 30, // 样式高
                                            anchor: { x: 10, y: 30 }, // 描点位置
                                        }),
                                    },
                                    geometries: [
                                        // 点标记数据数组
                                        {
                                            // 标记位置(纬度，经度，高度)
                                            position: center,
                                            id: "marker"
                                        },
                                    ],
                                });
                            });
                        })
                    } else {
                        this.data_ishow = true;
                    }
                }
                this.data_ishow = true;
            });
        },
        getWxSDK() {
            let that = this;
            var url = window.location.href.split("#")[0]; //动态获取链接
            var data = {
                url,
                mtype: "worker_agent_team_mp",
            };
            this.Request('client/api/wechat/jssdk/jsapi_ticket', 'get', data, false).then((res) => {
                res = res.data;
                wx.config({
                    debug: false, // true:是调试模式,调试时候弹窗,会打印出日志
                    appId: res.appId, // 微信appid
                    timestamp: res.timestamp, // 时间戳
                    nonceStr: res.nonceStr, // 随机字符串
                    signature: res.signature, // 签名
                    jsApiList: ["checkJsApi", "openLocation", "getLocation"],
                });
                wx.ready(() => {
                    this.location_value = 1;
                    clearTimeout(this.lj)
                    this.lj = setTimeout(() => {
                        if (this.location_value == 1) {
                            this.location_value = 2;
                            this.getList();
                        }
                    }, 3000);
                    wx.getLocation({
                        success: function (res) {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.latitude = res.latitude;
                                that.longitude = res.longitude;
                                that.getList();

                            }

                        },
                        cancel: function () {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.getList();
                                // Toast("您未开启地理位置授权");
                            }

                        },
                        fail: function () {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.getList();
                            }

                        },
                    });
                })
                wx.checkJsApi({
                    jsApiList: ["checkJsApi", "openLocation", "getLocation"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function () {
                        //在这里提示点击右上角分享
                    },
                });
            });
        },
        wx_location() {
            //let that =this;
            wx.getLocation({
                complete: function () {
                },
                cancel: function () {
                }, fail: function () {
                }, success: function (res) {
                    if (!res.longitude || !res.latitude) {
                        return false;
                    }
                }
            });
        },
        //解析定位结果
        onComplete(data) {
            this.latitude = data.position.lat;
            this.longitude = data.position.lng;
            this.getList();
        },
        //解析定位错误信息
        onError() {
            this.getList();
        },
        clickAddress(latitude, longitude, name, address) {
            wx.openLocation({
                latitude, // 纬度，浮点数，范围为90 ~ -90
                longitude, // 经度，浮点数，范围为180 ~ -180。
                name, // 位置名
                address, // 地址详情说明
                scale: 10, // 地图缩放级别,整形值,范围从1~28。默认为最大
            });
        },
    },
};
</script>
<style scoped>
.ceshi_btn {
    width: 50px;
    height: 50px;
    background: red;
    border-radius: 50%;
    position: fixed;
    bottom: 40px;
    left: 40px;
    z-index: 10000;
}
.address {
    margin: 30px;
    /* padding-top: 114px; */
}
.address > div {
    background: #fff;
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 10px;
    position: relative;
}
.address .map {
    width: 100%;
    height: 300px;
    position: relative;
    z-index: 1000;
}
.address .map_mask {
    width: calc(100% - 60px);
    height: 310px;
    position: absolute;
    z-index: 1000;
    top: 94px;
    opacity: 0;
}
.address h3 {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    margin-top: 10px;
}
.address .left {
    /* width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
    text-align: left;
}
.van-icon {
    vertical-align: middle;
    margin-top: 4px;
}
a .justifyContentBetten {
    margin-top: 20px;
}
.justifyContentBetten {
    margin: 10px 0;
}
</style>